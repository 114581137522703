<template>
  <div class="my">
    <h1>个人中心</h1>
    <el-form label-width="auto">
      <el-form-item>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
          <el-button type="info" @click="data.resetPasswordValue = ''; show.resetPasswordShow = true;">重置密码</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-avatar :size="200" :src="require('../../assets/logo.png')" style="margin: auto;" />
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="data.userInfo.nick" />
      </el-form-item>
      <el-form-item label="电话号">
        <el-input v-model="data.userInfo.phone" />
      </el-form-item>
      <el-form-item label="角色">
        <el-input v-model="data.userInfo.roleName" disabled />
      </el-form-item>
      <el-form-item label="特殊身份" v-if="data.userInfo.admin">
        <el-tag type="primary">超管</el-tag>
      </el-form-item>
    </el-form>
  </div>

  <el-dialog v-model="showData.resetPasswordShow" title="重置密码：" width="550" :close-on-click-modal="false" :close-on-press-escape="false">
    <p>
      <el-text>请输入新密码：</el-text>
    </p>
    <p>
      <el-input v-model="data.resetPasswordValue" placeholder="10~16位字符" />
    </p>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="resetPassword()">确认重置密码</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()

const data = reactive({
  userInfo: {},
  resetPasswordValue: ''
})

const showData = reactive({
  resetPasswordShow: false
})

api.get('/backend/user/getMyInfo').then(res => {
  data.userInfo = res
})

const save = () => {
  api.post('/backend/user/setMy', {
    nick: data.userInfo.nick,
    phone: data.userInfo.phone
  }).then(() => {
    const loginUser = cache.getObject(cache.keys.loginUser)
    loginUser.nick = data.userInfo.nick
    loginUser.phone = data.userInfo.phone
    cache.setObject(cache.keys.loginUser, loginUser)
    ElMessage.success('修改成功')
  })
}

const resetPassword = () => {
  if (data.resetPasswordValue.length < 10 || data.resetPasswordValue.length > 16) {
    ElMessage.error('密码长度为10-16位')
    return
  }
  api.post('/backend/user/resetMyPassword', { password: data.resetPasswordValue }).then(res => {
    ElMessage.success('重置密码成功')
    showData.resetPasswordShow = true
  })
}
</script>

<style lang="less">
.my {
  margin: auto;
  max-width: 660px;
}
</style>